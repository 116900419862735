import React, { Component } from "react";
//import { Helmet, HelmetProvider } from "react-helmet-async";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import $ from "jquery";
import { ValidateEmail, axiosConfig, normalize } from "../../config/utility";
import ReCAPTCHA from "react-google-recaptcha";
const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const newHeight = window.screen.height;
export class Theme9 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      dnsList: [],
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      currentPdfLink: "",
      currentlink: "",
      themeData: this.props.theme_data,
      showCaptcha: true,
    };
    this.site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    this.captcha_token = null;
  }

  get_props = () => {
    return this.props.theme_data;
  };
  scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  pre_loader() {
    $(function () {
      setTimeout(function () {
        $("#consua-preloader").addClass("loaded");
        $("#loading").fadeOut(500);

        if ($("#consua-preloader").hasClass("loaded")) {
          $("#preloader")
            .delay(900)
            .queue(function () {
              $(this).remove();
            });
        } else {
          $("#consua-preloader").addClass("loaded");
          $("#loading").fadeOut(500);
        }
      }, 2000);
    });
  }

  componentDidMount() {
    let theme_data = this.get_props();
    var propVal = theme_data;
    console.log("clicked", propVal);
    this.pre_loader();
    $(document).on("click", "#send_message", (e) => {
      e.preventDefault();
      

      //const { name, email, phone, subject, message } = this.state;
      let name = $("#name").val();
      let email = $("#email").val();
      let phone = $("#phone").val();
      let subject = $("#subject").val();
      let message = $("#message").val();
      console.log({
        name: name,
        email: email,
        subject: subject,
        token: process.env.REACT_APP_API_TOKEN,
        api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
        phone: phone,
        message: message,
        reseller_name: propVal.reseller_detail[0].reseller_comp,
        reseller_logo: propVal.reseller_detail[0].reseller_logo,
        agent_email: propVal.sharelink_settings[0].contact_email,
        agent_name: propVal.agent_data[0]["name"],
        sharelink_id: propVal.sharelink_settings[0].sharelink_id,
      });
      let is_validated = true;
      if (name === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Name is required</div>'
        );
        is_validated = false;
      } else if (email === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Email is required</div>'
        );
        is_validated = false;
      } else if (ValidateEmail(email) === false) {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Invalid email format.</div>'
        );

        is_validated = false;
      } else if (phone === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Phone is required</div>'
        );
        is_validated = false;
      } else if (subject === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Subject is required</div>'
        );
        is_validated = false;
      } else if (message === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Message is required</div>'
        );
        is_validated = false;
      }

      if (!is_validated) {
        return;
      }

      if (!this.captcha_token) {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Invalid captcha, please retry.</div>'
        );
        return;
      } else {
        this.setState({
          showCaptcha: false,
        });
      }

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "agents/submit_inquiry",
            JSON.stringify({
              name: name,
              email: email,
              subject: subject,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              phone: phone,
              message: message,
              reseller_name: propVal.reseller_detail[0].reseller_comp,
              reseller_logo: propVal.reseller_detail[0].reseller_logo,
              agent_email: propVal.sharelink_settings[0].contact_email,
              agent_name: propVal.agent_data[0]["name"],
              sharelink_id: propVal.sharelink_settings[0].sharelink_id,
            }),
            axiosConfig
          )
          .then((response) => {
            console.log("Contact Response.data : ", response.data);
            if (response.data.status === 404) {
              $("#aler_success").html("");
              $("#aler_error").html(
                '<div class="alert alert-danger" role="alert">There is some error while submitting your inquiry.</div>'
              );
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              $("#aler_error").html("");
              $("#aler_success").html(
                '<div class="alert alert-success" role="alert">Your inquiry has been sent successfully. We will contact you shortly.</div>'
              );
              $("#name").val("");
              $("#phone").val("");
              $("#subject").val("");
              $("#message").val("");
              $("#email").val("");

              this.captcha_token = null;
              this.setState({
                showCaptcha: true,
              });
            }
          });
      } catch (error) {
        $("#aler_success").html("");
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">There is some error while submitting your inquiry.</div>'
        );
        console.log("catch : ", error);
      }

      setTimeout(() => {
        $("#aler_error").html("");
        $("#aler_success").html("");
      }, 5000);
    });

    $(document).on("click", ".download_rate_deck", function () {
      console.log("clicked", propVal);
      var element = $(this);
      var parent = element.closest(".download_rate_deck");
      var page_id = parent.find(".downloadratedeck").val();
      console.log("PAGE ID: ", page_id);
      if (page_id !== "") {
        try {
          axios
            .post(
              process.env.REACT_APP_API_URL + "agents/get_rate_deck_fileurl",
              JSON.stringify({
                token: process.env.REACT_APP_API_TOKEN,
                api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
                page_id: page_id,
                reseller_accountno: propVal.agent_data[0]["reseller_accountno"],
              }),
              axiosConfig
            )
            .then((reportsData) => {
              console.log("Contact Response.data : ", reportsData.data);
              if (reportsData.data.status === 404) {
                $("#aler_success").html("");
                $("#aler_error").html(
                  '<div class="alert alert-danger" role="alert">There is some error while submitting your inquiry.</div>'
                );
              }

              if (
                reportsData.data.status === 200 &&
                reportsData.data.message === "success"
              ) {
                var fileName = "";
                var url = reportsData.data.data;
                var a = document.createElement("a");
                a.href = url;
                fileName = url.split("/").pop();
                //alert(fileName);
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
              }
            });
        } catch (error) {
          console.log("catch : ", error);
        }
      } else {
        console.log("Page ID is missing");
      }
      //window.location.href = click_route;
    });
  }

  handlePhoneNumber = (value) => {
    return value.replace(phoneRegex, "($1) $2-$3");
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  getSrviceTitle = (service_id) => {
    let theme_data = this.get_props();
    var propVal = theme_data.all_services;
    console.log("getSrviceTitle propVal: ", propVal);

    for (var i = 0, len = propVal.length; i < len; i++) {
      if (propVal[i].id === service_id) {
        let service_type = "";
        if (propVal[i].service_type === "did") {
          service_type = "DID Origination";
        } else if (propVal[i].service_type === "tfn") {
          service_type = "Toll Free Origination";
        } else if (propVal[i].service_type === "term") {
          service_type = "Voice Termination";
        } else if (propVal[i].service_type === "teams") {
          service_type = "Microsoft Teams UCAAS";
        } else if (propVal[i].service_type === "tfn_term") {
          service_type = "Toll Free Termination";
        } else if (propVal[i].service_type === "az_term") {
          service_type = "International A-Z Termination";
        } else if (propVal[i].service_type === "live_agent") {
          service_type = "Live Agent";
        } else if (propVal[i].service_type === "cnam") {
          service_type = "CNAM";
        } else if (propVal[i].service_type === "sms") {
          service_type = "SMS";
        }

        return (
          <>
            <td>{propVal[i].service_title}</td>
            <td>{service_type}</td>
          </>
        );
      }
    }
    return null;
  };

  fieldValidation = () => {
    let { name, email, phone, subject, message } = this.state;

    if (name === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Full Name is required.",
        errorcPass: "",
      });
      return false;
    } else if (subject === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorTitle: "error",
        errorMessage: "Subject is required.",
        errorcPass: "",
      });
      return false;
    } else if (email === "") {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorTitle: "",
        errorMessage: "Email is required.",
        errorcPass: "",
      });
      return false;
    } else if (message === "") {
      this.setState({
        errorMessage: "error",
        errorEmail: "",
        errorMessage: "Message is required.",
        errorTitle: "",
        errorcPass: "",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
        errorcPass: "",
      });
      return false;
    } else {
      this.setState({
        errorcPass: "",
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        errorConfirm: "",
        errorPhone: "",
        disabled: true,
      });
    }
    return true;
  };

  getColSize = () => {
    const length = this.props.theme_data.sharelink_services.length;
    if (length === 1) {
      return "12";
    }
    return length % 2 === 0 ? "6" : "4";
  };

  openLink = (link) => {
    this.setState({
      currentlink: link,
    });
    window.$("#modalMarketingLink").modal("show");
  };

  openPDFlink = (link) => {
    this.setState({
      currentPdfLink: link,
    });
    window.$("#modalMarketingPDFLink").modal("show");
  };

  modalHideMarketingLink = () => {
    this.setState({
      currentlink: "",
    });
    window.$("#modalMarketingLink").modal("hide");
  };

  modalHideMarketingPDF = () => {
    this.setState({
      currentPdfLink: "",
    });
    window.$("#modalMarketingPDFLink").modal("hide");
  };

  scrollToContactForm = () => {
    const contactForm = document.getElementById("contact");
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: "smooth" });
    }
  };
  handleCaptchaChange = (value) => {
    this.captcha_token = value;
  };
  render() {
    const { theme_data, key } = this.props;

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <meta
              name="description"
              content={this.props.theme_data.sharelink[0]["meta_description"]}
            />
            <title>{this.props.theme_data.sharelink[0]["meta_title"]}</title>
          </Helmet>
        </HelmetProvider>

        {/* === START PRELOADER === */}
        <div id="preloader">
          <div id="consua-preloader" className="consua-preloader">
            <div className="animation-preloader">
              <div className="spinner"></div>
              <div className="txt-loading">
                {this.props.theme_data.agent_data[0].comp_name
                  .split("")
                  .map((letter, index) => (
                    <span
                      key={index}
                      data-text-preloader={letter}
                      className="letters-loading"
                    >
                      {letter}
                    </span>
                  ))}
              </div>
            </div>
            <div className="loader">
              <div className="row">
                <div className="col-3 loader-section section-left">
                  <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-left">
                  <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-right">
                  <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-right">
                  <div className="bg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* === END PRELOADER === */}
        {/* === START HEADER TOP === */}
        <div className="top-bar-area top-bar-style-two">
          <div className="container">
            <div className="row align-center">
              <div className="col-lg-8">
                <ul className="item-flex">
                  {this.props.theme_data.sharelink_settings[0][
                    "contact_address"
                  ] &&
                  this.props.theme_data.sharelink_settings[0][
                    "contact_address"
                  ] !== "0" ? (
                    <li>
                      <div className="icon">
                        <i className="fas fa-map-marker-alt"></i>
                      </div>
                      <div className="info">
                        <strong>Address</strong>
                        <span>
                          {
                            this.props.theme_data.sharelink_settings[0][
                              "contact_address"
                            ]
                          }
                        </span>
                      </div>
                    </li>
                  ) : null}

                  {this.props.theme_data.sharelink_settings[0][
                    "contact_phone"
                  ] &&
                  this.props.theme_data.sharelink_settings[0][
                    "contact_phone"
                  ] !== "0" ? (
                    <li>
                      <div className="icon">
                        <i className="fas fa-user-headset"></i>
                      </div>
                      <div className="info">
                        <strong>Phone</strong>
                        {this.handlePhoneNumber(
                          this.props.theme_data.sharelink_settings[0][
                            "contact_phone"
                          ]
                        )}
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
              {this.props.theme_data.sharelink_settings[0]["contact_email"] ? (
                <div className="col-lg-4 text-end">
                  <div className="call-entry">
                    <div className="icon">
                      <i className="fas fa-comments-alt-dollar"></i>
                    </div>
                    <div className="info">
                      <p>Have any Questions?</p>
                      <h5>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.scrollToContactForm();
                          }}
                          href="#"
                        >
                          {
                            this.props.theme_data.sharelink_settings[0][
                              "contact_email"
                            ]
                          }
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* === END HEADER TOP === */}
        {/* === START HEADER === */}
        <header>
          {/* Start Navigation */}
          <nav className="navbar mobile-sidenav navbar-theme-secodnary navbar-common navbar-sticky navbar-default validnavs customClass-insuranceNav">
            {/* Start Top Search */}
            <div className="top-search">
              <div className="container-xl">
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <span className="input-group-addon close-search">
                    <i className="fa fa-times"></i>
                  </span>
                </div>
              </div>
            </div>
            {/* End Top Search */}

            <div className="container nav-box d-flex justify-content-between align-items-center">
              {/* Start Header Navigation */}
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-menu"
                >
                  <i className="fa fa-bars"></i>
                </button>
                <a className="navbar-brand" href="#">
                  {this.props.theme_data.logos ? (
                    <img
                      src={`${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.logos[0]["picture"]}`}
                      alt={this.props.theme_data.logos[0]["image_title"]}
                      className="logo"
                    />
                  ) : (
                    <img src="../../../assets/theme/img/logo/dark.png" alt="" />
                  )}
                </a>
              </div>
              {/* End Header Navigation */}

              {/* Main Nav */}
              <div className="main-nav-content">
                {/* Collect the nav links, forms, and other content for toggling */}
                <div className="collapse navbar-collapse" id="navbar-menu">
                  {this.props.theme_data.logos ? (
                    <img
                      src={`${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.logos[0]["picture"]}`}
                      alt={this.props.theme_data.logos[0]["image_title"]}
                      className="logo"
                    />
                  ) : (
                    <img src="../../../assets/theme/img/logo/dark.png" alt="" />
                  )}
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target="#navbar-menu"
                  >
                    <i className="fa fa-times"></i>
                  </button>

                  <ul
                    className="nav navbar-nav navbar-right"
                    data-in="fadeInDown"
                    data-out="fadeOutUp"
                  >
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.scrollToSection("home");
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.scrollToSection("about");
                        }}
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.scrollToSection("service");
                        }}
                      >
                        Services
                      </a>
                    </li>
                    {this.props.theme_data.sharelink_settings[0][
                      "contact_email"
                    ] ? (
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.scrollToSection("contact");
                          }}
                        >
                          Contact
                        </a>
                      </li>
                    ) : null}
                    <li>
                      <a
                        href={`${
                          process.env.REACT_APP_AGENT_PORTAL_URL +
                          this.props.theme_data.reseller_detail[0][
                            "reseller_domain_identifier"
                          ]
                        }/client/login`}
                      >
                        Login / Sign Up
                      </a>
                    </li>
                  </ul>
                </div>
                {/* End /.navbar-collapse */}

                <div className="attr-right">
                  {/* Start Atribute Navigation */}
                  <div className="attr-nav attr-box">
                    <ul>
                      <li className="side-menu">
                        <a href="#">
                          <span className="bar-1"></span>
                          <span className="bar-2"></span>
                          <span className="bar-3"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* End Atribute Navigation */}
                </div>

                {/* Start Side Menu */}
                <div class="side customInsurance-side">
                  <a href="#" class="close-side">
                    <i class="icon_close"></i>
                  </a>
                  <div class="widget">
                    <div class="logo">
                      {this.props.theme_data.logos ? (
                        <img
                          src={`${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.logos[0]["picture"]}`}
                          alt={this.props.theme_data.logos[0]["image_title"]}
                          className="logo"
                        />
                      ) : (
                        <img
                          src="../../../assets/theme/img/logo/dark.png"
                          alt=""
                        />
                      )}
                    </div>
                    <p>
                      Thank you for considering{" "}
                      {this.props.theme_data.agent_data[0].comp_name}. We are
                      dedicated to providing exceptional service and addressing
                      your needs promptly. Please feel free to reach out to us
                      through the provided contact information below.
                    </p>
                  </div>
                  <div class="widget address">
                    <div>
                      <ul>
                        {this.props.theme_data.sharelink_settings[0][
                          "contact_address"
                        ] &&
                        this.props.theme_data.sharelink_settings[0][
                          "contact_address"
                        ] !== "0" ? (
                          <li>
                            <div class="content">
                              <p>Address</p>
                              <strong>
                                {
                                  this.props.theme_data.sharelink_settings[0][
                                    "contact_address"
                                  ]
                                }
                              </strong>
                            </div>
                          </li>
                        ) : null}
                        {this.props.theme_data.sharelink_settings[0][
                          "contact_email"
                        ] ? (
                          <li>
                            <div class="content">
                              <p>Email</p>
                              <strong>
                                {
                                  this.props.theme_data.sharelink_settings[0][
                                    "contact_email"
                                  ]
                                }
                              </strong>
                            </div>
                          </li>
                        ) : null}
                        {this.props.theme_data.sharelink_settings[0][
                          "contact_phone"
                        ] &&
                        this.props.theme_data.sharelink_settings[0][
                          "contact_phone"
                        ] !== "0" ? (
                          <li>
                            <div class="content">
                              <p>Contact</p>
                              <strong>
                                {this.handlePhoneNumber(
                                  this.props.theme_data.sharelink_settings[0][
                                    "contact_phone"
                                  ]
                                )}
                              </strong>
                            </div>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                  <div class="widget social">
                    <ul>
                      {this.props.theme_data.sharelink_settings[0]
                        .facebook_link !== "" &&
                      this.props.theme_data.sharelink_settings[0]
                        .facebook_link !== "https://www.facebook.com/" ? (
                        <li>
                          <a
                            href={
                              this.props.theme_data.sharelink_settings[0]
                                .facebook_link
                            }
                            target="_blank"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                      ) : null}
                      {this.props.theme_data.sharelink_settings[0]
                        .twitter_link !== "" &&
                      this.props.theme_data.sharelink_settings[0]
                        .twitter_link !== "https://twitter.com/" ? (
                        <li>
                          <a
                            href={
                              this.props.theme_data.sharelink_settings[0]
                                .twitter_link
                            }
                            target="_blank"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                      ) : null}
                      {this.props.theme_data.sharelink_settings[0]
                        .instagram_link !== "" &&
                      this.props.theme_data.sharelink_settings[0]
                        .instagram_link !== "https://www.instagram.com/" ? (
                        <li>
                          <a
                            href={
                              this.props.theme_data.sharelink_settings[0]
                                .instagram_link
                            }
                            target="_blank"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      ) : null}
                      {this.props.theme_data.sharelink_settings[0]
                        .linkedin_link !== "" &&
                      this.props.theme_data.sharelink_settings[0]
                        .linkedin_link !== "https://www.linkedin.com/" ? (
                        <li>
                          <a
                            href={
                              this.props.theme_data.sharelink_settings[0]
                                .linkedin_link
                            }
                            target="_blank"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      ) : null}
                      {this.props.theme_data.sharelink_settings[0]
                        .youtube_link !== "" &&
                      this.props.theme_data.sharelink_settings[0]
                        .youtube_link !== "https://www.youtube.com/" ? (
                        <li>
                          <a
                            href={
                              this.props.theme_data.sharelink_settings[0]
                                .youtube_link
                            }
                            target="_blank"
                          >
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                {/* End Side Menu */}

                {/* Overlay screen for menu */}
                <div className="overlay-screen"></div>
                {/* End Overlay screen for menu */}
              </div>
              {/* Main Nav */}
            </div>
            {/* Overlay screen for menu */}
            <div className="overlay-screen"></div>
            {/* End Overlay screen for menu */}
          </nav>
          {/* End Navigation */}
        </header>
        {/* === END HEADER === */}
        {/* === START BANNER AREA === */}
        <div
          id="home"
          className="banner-area bg-gray navigation-circle banner-style-three-area overflow-hidden"
        >
          {/* Slider main container */}
          <div className="banner-style-two-carousel">
            {/* Additional required wrapper */}
            <div className="swiper-wrapper">
              {/* Single Item */}
              <div className="swiper-slide banner-style-three">
                <div
                  className="banner-thumb bg-cover"
                  style={
                    this.props.theme_data.banners
                      ? {
                          background: `url(${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.banners[0]["picture"]})`,
                        }
                      : {
                          background:
                            "url(../../../assets/theme/img//banner21.jpg)",
                        }
                  }
                ></div>
                <div className="container">
                  <div className="row align-center">
                    <div className="col-xl-6 col-lg-7 col-md-10">
                      <div className="content">
                        <h4>{this.props.theme_data.agent_data[0].name} </h4>
                        <h2>
                          {
                            this.props.theme_data.sharelink_settings[0]
                              .about_me_short
                          }
                        </h2>
                        <div className="button">
                          <a
                            className="btn btn-theme secondary btn-md animation custom_btn-Quotation3"
                            href={this.props.currentUrl}
                            target="_blank"
                          >
                            REQUEST FOR QUOTATION
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="banner-shape">
                  <img
                    src="../../../assets/theme/img/shape/43.png"
                    alt="Shape"
                  />
                </div>
              </div>
              {/* End Single Item */}
            </div>
          </div>
        </div>
        {/* === END BANNER AREA === */}
        {/* === START ABOUT === */}
        <div
          id="about"
          className="about-style-four-area overflow-hidden bg-gray default-padding-top shape-light-bottom"
        >
          <div className="shape-right-bottom-actual">
            <img
              src="../../../assets/theme/img/shape/51.png"
              alt="Image Not Found"
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-5 offset-lg-1 about-style-four">
                <h4 className="sub-heading secondary">About Us</h4>
                <h2 className="title">
                  Creating a better future for your loved once
                </h2>
                <div className="thumb mt-50 mt-xs-30">
                  {this.props.theme_data.avatars ? (
                    <img
                      className="wow fadeInRight"
                      src={`${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.avatars[0]["picture"]}`}
                      alt="Image Not Found"
                    />
                  ) : (
                    <img
                      src="../../../assets/theme/img/about21.jpg"
                      alt="Image Not Found"
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6 about-style-four">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      this.props.theme_data.sharelink_settings[0].about_me,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* === END ABOUT === */}
        {/* === START SERVICES === */}
        <div
          id="service"
          className="services-style-two-area default-padding bottom-less bg-cover bg-gray"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="site-heading secondary text-center">
                  <h4 className="sub-heading">
                    Product & Services We're Offering
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <table className="table servicesTable">
                <thead className="servicesTable-thead thead-secondary">
                  <tr>
                    <th scope="col" className="col-1">
                      SKU
                    </th>
                    <th scope="col" className="col-4">
                      NAME
                    </th>
                    <th scope="col" className="col-2">
                      DOWNLOAD
                    </th>
                    <th scope="col" className="col-2">
                      PRICE
                    </th>
                    <th scope="col" className="col-1">
                      VIEW
                    </th>
                    <th scope="col" className="col-2">
                      ORDER
                    </th>
                  </tr>
                </thead>
                <tbody className="servicesTable-body">
                  {this.props.theme_data.sharelink_services.map((services) => (
                    <tr className="servicesTable-row">
                      <td scope="row" style={{ fontWeight: "500" }}>
                        {services.sku && services.sku !== ""
                          ? services.sku
                          : "---"}
                      </td>
                      <td>{services.service_title}</td>
                      {services.marketing_content === "pdf" ? (
                        <>
                          {services.marketing_link &&
                          services.marketing_link !== "" ? (
                            <td>
                              <a
                                className="servicesTable-redirectLink"
                                style={{ cursor: "pointer" }}
                                href="#"
                                onClick={() =>
                                  this.openPDFlink(services.marketing_link)
                                }
                              >
                                <input
                                  type="hidden"
                                  name="download_rate_deck"
                                  className="downloadratedeck"
                                  value={services.rate_deck_file}
                                />
                                <i class="fas fa-file"></i> View file
                              </a>
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                        </>
                      ) : services.marketing_content === "link" ? (
                        <>
                          {services.marketing_link &&
                          services.marketing_link !== "" ? (
                            <td>
                              <a
                                className="servicesTable-redirectLink"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.openLink(services.marketing_link)
                                }
                              >
                                <input
                                  type="hidden"
                                  name="download_rate_deck"
                                  className="downloadratedeck"
                                  value={services.rate_deck_file}
                                />
                                <i class="fas fa-external-link-alt"></i> Open
                                link
                              </a>
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                        </>
                      ) : null}
                      <td style={{ fontWeight: "500" }}>
                        {services.nda_required === "Yes"
                          ? "Sign Up to view pricing"
                          : "$" + services.rate.toFixed(2)}
                      </td>
                      <td>
                        <a
                          type="button"
                          className="serviceTable-infoIcon-secondary"
                          data-bs-toggle="modal"
                          data-bs-target={`#serviceModal${services.id}`}
                          href="#"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-info-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"></path>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"></path>
                          </svg>
                        </a>
                      </td>
                      <td>
                        <a
                          className="btn btn-theme btn-md secondary animation order_btn customStyle-tableOrderNow-btn"
                          href={`${
                            services.domain_name && services.domain_name !== ""
                              ? `${services.domain_name}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`
                              : `${process.env.REACT_APP_AGENT_PORTAL_URL}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`
                          }`}
                        >
                          {services.nda_required === "Yes"
                            ? "Sign Up"
                            : "Order Now"}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div id="service" className="services-style-two-area default-padding bottom-less bg-cover bg-gray"  style={{ backgroundImage: "url(../../../assets/theme/img/shape/27.png)" }}>
        <div class="container">
              <div class="row">
                  <div class="col-lg-8 offset-lg-2">
                      <div class="site-heading text-center">
                          <h4 class="sub-heading">What we do</h4>
                          <h2 class="title">Product & Services We're Offering</h2>
                          <div class="devider"></div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container">
            <div className="row customRow-theme4">
              {this.props.theme_data.sharelink_services.map((services, index) => (
                <div key={`serv${services.id}`} className={`col-lg-${this.getColSize()} col-xl-${this.getColSize()} col-md-6 mb-30`}>
                  <div className="services-style-two">
                    {/* <div className="thumb">
                      <img src="../../../assets/theme/img/service_img.jpg" alt="Thumb" />
                      <div className="title">
                        <a type="button" data-bs-toggle="modal" data-bs-target={`#serviceModal${services.id}`} href="#">
                          <i className="flaticon-bar-chart"></i>
                          <h4>{services.service_title}</h4>
                        </a>
                      </div>
                    </div> */}
        {/* <div className="info"> */}
        {/*<p
                        className="text"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        dangerouslySetInnerHTML={{ __html: services.product_desc }}
                      ></p>*/}
        {/* <div className="button">
                        <h4 className="fw-500" style={{fontSize: "24px"}}>{services.service_title}</h4>
                        <h5 className="">Rate: ${services.rate.toFixed(2)}</h5>
                        <a type="button" data-bs-toggle="modal" data-bs-target={`#serviceModal${services.id}`} href="#">View Details</a>
                        <div className="devider"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* === END SERVICES === */}
        {/* === START CONTACT-US === */}
        {this.props.theme_data.sharelink_settings[0]["contact_email"] ? (
          <div
            id="contact"
            className="contact-style-one-area overflow-hidden secondary default-padding"
          >
            <div className="contact-shape">
              <img
                src="../../../assets/theme/img/shape/37.png"
                alt="Image Not Found"
              />
            </div>

            <div className="container">
              <div className="row align-center">
                <div className="contact-stye-one col-lg-5 mb-md-50 mb-xs-20">
                  <div className="contact-style-one-info">
                    <h2>Contact Information</h2>
                    <p>
                      For any questions or concerns, please don't hesitate to
                      contact us.
                    </p>
                    <ul>
                      {this.props.theme_data.sharelink_settings[0][
                        "contact_phone"
                      ] &&
                      this.props.theme_data.sharelink_settings[0][
                        "contact_phone"
                      ] !== "0" ? (
                        <li className="wow fadeInUp">
                          <div className="icon">
                            <i className="fas fa-phone-alt"></i>
                          </div>
                          <div className="content">
                            <h5 className="title">Hotline</h5>
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {this.handlePhoneNumber(
                                this.props.theme_data.sharelink_settings[0][
                                  "contact_phone"
                                ]
                              )}
                            </a>
                          </div>
                        </li>
                      ) : null}

                      {this.props.theme_data.sharelink_settings[0][
                        "contact_address"
                      ] &&
                      this.props.theme_data.sharelink_settings[0][
                        "contact_address"
                      ] !== "0" ? (
                        <li className="wow fadeInUp" data-wow-delay="300ms">
                          <div className="icon">
                            <i className="fas fa-map-marker-alt"></i>
                          </div>
                          <div className="info">
                            <h5 className="title">Our Location</h5>
                            <p>
                              {
                                this.props.theme_data.sharelink_settings[0][
                                  "contact_address"
                                ]
                              }
                            </p>
                          </div>
                        </li>
                      ) : null}
                      {this.props.theme_data.sharelink_settings[0][
                        "contact_email"
                      ] ? (
                        <li className="wow fadeInUp" data-wow-delay="500ms">
                          <div className="icon">
                            <i className="fas fa-envelope-open-text"></i>
                          </div>
                          <div className="info">
                            <h5 className="title">Official Email</h5>
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {
                                this.props.theme_data.sharelink_settings[0][
                                  "contact_email"
                                ]
                              }
                            </a>
                          </div>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>

                <div className="contact-stye-one col-lg-7 pl-60 pl-md-15 pl-xs-15">
                  <div className="contact-form-style-one">
                    <h4 className="sub-heading secondary">Have Questions?</h4>
                    <h2 className="heading">Send us a Message</h2>
                    <form
                      method="POST"
                      id="contact_form"
                      autoComplete="off"
                      className="contact-form contact-form"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div id="aler_error"></div>
                          <div id="aler_success"></div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              id="name"
                              name="name"
                              className="form-control"
                              defaultValue={this.state.name}
                              onChange={this.handleChange}
                              type="text"
                              placeholder="Name *"
                              maxLength={100}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              id="email"
                              name="email"
                              className="form-control"
                              defaultValue={this.state.email}
                              onChange={this.handleChange}
                              type="text"
                              placeholder="Email *"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              id="phone"
                              name="phone"
                              className="form-control"
                              defaultValue={this.state.phone}
                              onChange={this.handleChange}
                              type="text"
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/gi,
                                  ""
                                );
                              }}
                              placeholder="Phone *"
                              maxLength={12}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              id="subject"
                              name="subject"
                              className="form-control"
                              defaultValue={this.state.subject}
                              onChange={this.handleChange}
                              type="text"
                              placeholder="Subject *"
                              maxLength={50}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            className="form-group comments"
                            style={{ marginBottom: "17px" }}
                          >
                            <textarea
                              id="message"
                              name="message"
                              className="form-control"
                              onChange={this.handleChange}
                              defaultValue={this.state.message}
                              placeholder="Message *"
                              maxLength={500}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          {this.state.showCaptcha && (
                            <ReCAPTCHA
                              sitekey={this.site_key}
                              onChange={this.handleCaptchaChange}
                              style={{ padding: "0px 0px 15px" }}
                            />
                          )}
                          <button
                            id="send_message"
                            className="btn btn-theme secondary btn-md animation"
                          >
                            <i className="fa fa-paper-plane"></i> Get in Touch
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-12 alert-notification">
                        <div id="message" className="alert-msg">
                          {this.state.errorMessage !== "" ? (
                            <>
                              <div style={{ clear: "both" }}></div>
                              <div className="alert alert-danger" role="alert">
                                {this.state.errorMessage}
                              </div>
                            </>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <>
                              <div style={{ clear: "both" }}></div>
                              <div className="alert alert-success" role="alert">
                                {this.state.successMessage}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {/* === END CONTACT-US === */}
        {/* === START FOOTER === */}
        <footer className="bg-dark text-light">
          <div className="footer-shape">
            <div className="item bottom">
              <img src="../../../assets/theme/img/shape/38.png" alt="Shape" />
            </div>
            <div className="item">
              <img src="../../../assets/theme/img/shape/9.png" alt="Shape" />
            </div>
          </div>
          <div className="container">
            <div className="f-items relative pt-70 pb-120 pt-xs-0 pb-xs-50">
              <div className="row">
                <div className="col-lg-4 col-md-6 footer-item pr-50 pr-xs-15">
                  <div className="f-item about">
                    {this.props.theme_data.logos ? (
                      <img
                        src={`${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.logos[0]["picture"]}`}
                        alt={this.props.theme_data.logos[0]["image_title"]}
                        className="logo"
                      />
                    ) : (
                      <img src="../../../assets/img/logo/dark.png" alt="" />
                    )}

                    {this.props.theme_data.agent_data[0].comp_name ? (
                      <p>{this.props.theme_data.agent_data[0].comp_name}</p>
                    ) : (
                      <p>Not Set</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 footer-item">
                  <div className="f-item link">
                    <h4 className="widget-title">Our Company</h4>
                    <ul>
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.scrollToSection("home");
                          }}
                        >
                          Home
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.scrollToSection("about");
                          }}
                        >
                          About Us
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.scrollToSection("service");
                          }}
                        >
                          Services
                        </a>
                      </li>
                      {this.props.theme_data.sharelink_settings[0][
                        "contact_email"
                      ] ? (
                        <li>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.scrollToSection("contact");
                            }}
                          >
                            Contact
                          </a>
                        </li>
                      ) : null}
                      <li>
                        <a
                          href={`${
                            process.env.REACT_APP_AGENT_PORTAL_URL +
                            this.props.theme_data.reseller_detail[0][
                              "reseller_domain_identifier"
                            ]
                          }/client/login`}
                        >
                          Login / Sign Up
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {(this.props.theme_data.sharelink_settings[0].facebook_link !==
                  "" &&
                  this.props.theme_data.sharelink_settings[0].facebook_link !==
                    "https://www.facebook.com/") ||
                (this.props.theme_data.sharelink_settings[0].twitter_link !==
                  "" &&
                  this.props.theme_data.sharelink_settings[0].twitter_link !==
                    "https://twitter.com/") ||
                (this.props.theme_data.sharelink_settings[0].instagram_link !==
                  "" &&
                  this.props.theme_data.sharelink_settings[0].instagram_link !==
                    "https://www.instagram.com/") ||
                (this.props.theme_data.sharelink_settings[0].linkedin_link !==
                  "" &&
                  this.props.theme_data.sharelink_settings[0].linkedin_link !==
                    "https://www.linkedin.com/") ||
                (this.props.theme_data.sharelink_settings[0].youtube_link !==
                  "" &&
                  this.props.theme_data.sharelink_settings[0].youtube_link !==
                    "https://www.youtube.com/") ? (
                  <div className="col-lg-4 col-md-6 footer-item">
                    <h4 className="widget-title">Our Socials</h4>
                    <p>
                      Stay connected and get the latest updates <br /> by
                      following us on social media!
                    </p>
                    <ul className="footer-social">
                      {this.props.theme_data.sharelink_settings[0]
                        .facebook_link !== "" &&
                      this.props.theme_data.sharelink_settings[0]
                        .facebook_link !== "https://www.facebook.com/" ? (
                        <li>
                          <a
                            href={
                              this.props.theme_data.sharelink_settings[0]
                                .facebook_link
                            }
                            target="_blank"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                      ) : null}
                      {this.props.theme_data.sharelink_settings[0]
                        .twitter_link !== "" &&
                      this.props.theme_data.sharelink_settings[0]
                        .twitter_link !== "https://twitter.com/" ? (
                        <li>
                          <a
                            href={
                              this.props.theme_data.sharelink_settings[0]
                                .twitter_link
                            }
                            target="_blank"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                      ) : null}
                      {this.props.theme_data.sharelink_settings[0]
                        .instagram_link !== "" &&
                      this.props.theme_data.sharelink_settings[0]
                        .instagram_link !== "https://www.instagram.com/" ? (
                        <li>
                          <a
                            href={
                              this.props.theme_data.sharelink_settings[0]
                                .instagram_link
                            }
                            target="_blank"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      ) : null}
                      {this.props.theme_data.sharelink_settings[0]
                        .linkedin_link !== "" &&
                      this.props.theme_data.sharelink_settings[0]
                        .linkedin_link !== "https://www.linkedin.com/" ? (
                        <li>
                          <a
                            href={
                              this.props.theme_data.sharelink_settings[0]
                                .linkedin_link
                            }
                            target="_blank"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      ) : null}
                      {this.props.theme_data.sharelink_settings[0]
                        .youtube_link !== "" &&
                      this.props.theme_data.sharelink_settings[0]
                        .youtube_link !== "https://www.youtube.com/" ? (
                        <li>
                          <a
                            href={
                              this.props.theme_data.sharelink_settings[0]
                                .youtube_link
                            }
                            target="_blank"
                          >
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* Start Footer Bottom */}
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <p>
                    &copy; Copyright {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a href="#">
                      {this.props.theme_data.reseller_detail[0].reseller_comp}
                    </a>
                  </p>
                </div>
                {/*<div className="col-lg-6 text-end">
                  <ul>
                    <li>
                      <a href="about-us.html">Terms</a>
                    </li>
                    <li>
                      <a href="about-us.html">Privacy</a>
                    </li>
                    <li>
                      <a href="contact-us.html">Support</a>
                    </li>
                  </ul>
                </div>*/}
              </div>
            </div>
          </div>
          {/* End Footer Bottom */}
        </footer>
        {/* === END FOOTER === */}
        {/* === START MODALS === */}
        {this.props.theme_data.sharelink_services.map((services) => (
          <div key={`serv${services.id}`}>
            <div
              className="modal fade"
              id={`serviceModal${services.id}`}
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby={`serviceModalLabel${services.id}`}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered service_modal">
                <div className="modal-content">
                  <button
                    type="button"
                    className="btn-close customStyle_modalCloseBtn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div className="modal-body">
                    <div className="soft-overview customSoft-overview-theme6">
                      <div className="customOverlay-theme3">
                        <div className="services-details-area overflow-hidden pt-50 pb-50">
                          <div className="container">
                            <div className="services-details-items">
                              <div className="row">
                                <div className="order-lg-last px-50 px-md-15 px-xs-15">
                                  <div className="row">
                                    <div className="col-lg-7">
                                      <h2 className="d-inline-block">
                                        {services.service_title}
                                      </h2>
                                    </div>
                                    <div className="col-lg-5 order_btn_wrapper_div">
                                      <div className="order_btn_div">
                                        <a
                                          className="btn btn-theme secondary btn-md animation order_btn customStyle-modalBtn mb-10"
                                          href={`${
                                            services.domain_name &&
                                            services.domain_name !== ""
                                              ? `${services.domain_name}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`
                                              : `${process.env.REACT_APP_AGENT_PORTAL_URL}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`
                                          }`}
                                        >
                                          {services.nda_required === "Yes"
                                            ? "Sign Up"
                                            : "Order Now"}
                                        </a>
                                        {services.nda_required === "Yes" ? (
                                          <p
                                            className="order_btn_price"
                                            style={{ fontSize: "14px" }}
                                          >
                                            * Sign Up to view pricing
                                          </p>
                                        ) : (
                                          <h3 className="order_btn_price">
                                            ${services.rate.toFixed(2)}
                                          </h3>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <p
                                    className="mb-40"
                                    dangerouslySetInnerHTML={{
                                      __html: services.product_desc,
                                    }}
                                  />
                                  {services.marketing_content === "pdf" &&
                                  services.marketing_link &&
                                  services.marketing_link !== "" ? (
                                    <div className="services-sidebar">
                                      <div className="single-widget widget-brochure customWidgetBrochure-theme6">
                                        <h4 className="widget-title customWidgetTitle-theme6">
                                          Files
                                        </h4>
                                        <ul>
                                          <li>
                                            <a
                                              className="customRedirectBtn-theme6"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.openPDFlink(
                                                  services.marketing_link
                                                )
                                              }
                                            >
                                              <i
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "24px",
                                                }}
                                                className="fas fa-file"
                                              ></i>
                                              <input
                                                type="hidden"
                                                name="download_rate_deck"
                                                className="downloadratedeck"
                                                value={services.rate_deck_file}
                                              />
                                              View file
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ) : services.marketing_content === "link" &&
                                    services.marketing_link &&
                                    services.marketing_link !== "" ? (
                                    <div className="services-sidebar">
                                      <div className="single-widget widget-brochure customWidgetBrochure-theme6">
                                        {/* <h4 className="widget-title customWidgetTitle-theme6">Links</h4> */}
                                        <ul>
                                          <li>
                                            <a
                                              className="customRedirectBtn-theme6"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.openLink(
                                                  services.marketing_link
                                                )
                                              }
                                            >
                                              <i
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "20px",
                                                }}
                                                className="fa fa-external-link"
                                              ></i>
                                              <input
                                                type="hidden"
                                                name="download_rate_deck"
                                                className="downloadratedeck"
                                                value={services.rate_deck_file}
                                              />
                                              Open link
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ) : null}
                                  {services.nda_required === "No" ? (
                                    <>
                                      {(services.misc_settings &&
                                        services.misc_settings.some(
                                          (setting) =>
                                            setting.misc_title !== null
                                        )) ||
                                      (services.other_service_settings.some(
                                        (setting) =>
                                          setting.generic_title !== null
                                      ) &&
                                        services.other_service_settings.length >
                                          1) ? (
                                        <div className="faq-style-one dark mt-40">
                                          <h3 className="mb-30">
                                            Pricing Detail
                                          </h3>
                                          <div
                                            className="accordion"
                                            id="faqAccordion"
                                          >
                                            {services.service_type === "did" ||
                                            services.service_type === "tfn" ||
                                            services.service_type ===
                                              "teams" ? (
                                              <div className="">
                                                <h5
                                                  className=""
                                                  id={`heading-${services.id}-details`}
                                                >
                                                  Rates
                                                </h5>
                                                <div
                                                  id={`collapse-${services.id}-details`}
                                                  className=""
                                                  aria-labelledby={`heading-${services.id}-details`}
                                                >
                                                  <div className="">
                                                    {services.domain_name &&
                                                    services.domain_name !==
                                                      "" ? (
                                                      <input
                                                        type="hidden"
                                                        name="click_route"
                                                        className="click_route"
                                                        value={`${services.domain_name}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`}
                                                      />
                                                    ) : (
                                                      <input
                                                        type="hidden"
                                                        name="click_route"
                                                        className="click_route"
                                                        value={`${process.env.REACT_APP_AGENT_PORTAL_URL}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`}
                                                      />
                                                    )}
                                                    <div className="table-responsive">
                                                      <table className="table">
                                                        <thead>
                                                          <tr>
                                                            <th>Type</th>
                                                            <th>Value</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {services.service_type ===
                                                            "did" ||
                                                          services.service_type ===
                                                            "tfn" ||
                                                          services.service_type ===
                                                            "teams" ? (
                                                            <>
                                                              <tr>
                                                                <th>MRC</th>
                                                                <td>
                                                                  $
                                                                  {services.mrc.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <th>NRC</th>
                                                                <td>
                                                                  $
                                                                  {services.nrc.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                              {services.porting_settings &&
                                                              services
                                                                .porting_settings
                                                                .length > 0 ? (
                                                                <>
                                                                  <tr>
                                                                    <th>
                                                                      Port-in
                                                                      Rate
                                                                    </th>
                                                                    <td>
                                                                      $
                                                                      {services.porting_settings[0].portin_sell_rate.toFixed(
                                                                        2
                                                                      )}
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <th>
                                                                      Port-out
                                                                      Rate
                                                                    </th>
                                                                    <td>
                                                                      $
                                                                      {services.porting_settings[0].portout_sell_rate.toFixed(
                                                                        2
                                                                      )}
                                                                    </td>
                                                                  </tr>
                                                                </>
                                                              ) : null}
                                                            </>
                                                          ) : null}
                                                          {/* {services.main_type !==
                                                                      "ratedeck" &&
                                                                    services.service_type !==
                                                                      "sms" &&
                                                                    services.service_type !==
                                                                      "cnam" ? (
                                                                      <tr>
                                                                        <th>
                                                                        {services.service_type ===
                                                              "generic"
                                                                ? "Price"
                                                                : "Rate Per Minute"}
                                                                        </th>
                                                                        <td>
                                                                          $
                                                                          {services.rate.toFixed(
                                                                            2
                                                                          )}
                                                                        </td>
                                                                      </tr>
                                                                    ) : null} */}
                                                          {services.service_type ===
                                                          "sms" ? (
                                                            <>
                                                              <tr>
                                                                <th>
                                                                  SMS In Rate
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.sms_in.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <th>
                                                                  SMS Out Rate
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.sms_out.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            </>
                                                          ) : null}
                                                          {services.service_type ===
                                                          "cnam" ? (
                                                            <>
                                                              <tr>
                                                                <th>
                                                                  Update CNAM
                                                                  (MRC)
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.cnam_mrc.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <th>
                                                                  DIP Charges
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.cnam_dip.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <th>
                                                                  Storage DIP
                                                                  Charges
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.cnam_dip_local.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            </>
                                                          ) : null}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {services.misc_settings &&
                                            services.misc_settings.length > 0 &&
                                            services.misc_settings.some(
                                              (misc) =>
                                                Number(misc.misc_rate) > 0
                                            ) ? (
                                              <>
                                                <div className="">
                                                  <h5
                                                    className=""
                                                    id={`heading-${services.id}-misc`}
                                                  >
                                                    Miscellaneous Charges
                                                  </h5>
                                                  <div
                                                    id={`collapse-${services.id}-misc`}
                                                    className=""
                                                    aria-labelledby={`heading-${services.id}-misc`}
                                                  >
                                                    <div className="customServiceTableStyles">
                                                      <table className="table">
                                                        <thead>
                                                          <tr>
                                                            <th data-label="Title">
                                                              Title
                                                            </th>
                                                            <th data-label="Type">
                                                              Type
                                                            </th>
                                                            <th data-label="Rate">
                                                              Rate
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {services.misc_settings.map(
                                                            (misc, ii) => (
                                                              <tr
                                                                key={`mis${ii}`}
                                                              >
                                                                <th data-label="Title">
                                                                  {
                                                                    misc.misc_title
                                                                  }
                                                                </th>
                                                                <td data-label="Type">
                                                                  {misc.misc_type ===
                                                                  "one_time"
                                                                    ? "One Time"
                                                                    : null}
                                                                  {misc.misc_type ===
                                                                  "per_minute"
                                                                    ? "Per Minute"
                                                                    : null}
                                                                  {misc.misc_type ===
                                                                  "per_hour"
                                                                    ? "Per Hour"
                                                                    : null}
                                                                </td>
                                                                <td data-label="Rate">
                                                                  $
                                                                  {Number(
                                                                    misc.misc_rate
                                                                  ).toFixed(2)}
                                                                </td>
                                                              </tr>
                                                            )
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                            {services.service_type &&
                                            services.service_type ===
                                              "generic" &&
                                            services.other_service_settings
                                              .length > 1 ? (
                                              <>
                                                <div className="">
                                                  <h5
                                                    className=""
                                                    id={`heading-${services.id}-other`}
                                                  >
                                                    Pricing Breakdown
                                                  </h5>
                                                  <div
                                                    id={`collapse-${services.id}-other`}
                                                    className=""
                                                    aria-labelledby={`heading-${services.id}-other`}
                                                  >
                                                    <div className="customServiceTableStyles">
                                                      <table className="table">
                                                        <thead>
                                                          <tr>
                                                            <th data-label="Title">
                                                              Title
                                                            </th>
                                                            <th data-label="Charge Type">
                                                              Charge Type
                                                            </th>
                                                            <th data-label="Rate Type">
                                                              Rate Type
                                                            </th>
                                                            <th data-label="Rate">
                                                              Rate
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {services.other_service_settings.map(
                                                            (misc, ids) => (
                                                              <tr
                                                                key={`mis${ids}`}
                                                              >
                                                                <th data-label="Title">
                                                                  {
                                                                    misc.generic_title
                                                                  }
                                                                </th>
                                                                <td data-label="Charge Type">
                                                                  {misc.pay_type ===
                                                                  "recurring"
                                                                    ? "Recurring"
                                                                    : ""}

                                                                  {misc.pay_type ===
                                                                  "one_time"
                                                                    ? "One Time"
                                                                    : ""}
                                                                </td>
                                                                <td data-label="Rate Type">
                                                                  {misc.pay_type ===
                                                                  "recurring" ? (
                                                                    <>
                                                                      {misc.generic_type ===
                                                                      "per_minute"
                                                                        ? "Per Minute"
                                                                        : ""}
                                                                      {misc.generic_type ===
                                                                      "per_hour"
                                                                        ? "Per Hour"
                                                                        : ""}
                                                                      {misc.generic_type ===
                                                                      "per_second"
                                                                        ? "Per Second"
                                                                        : ""}
                                                                      {misc.generic_type ===
                                                                      "per_day"
                                                                        ? "Per Day"
                                                                        : ""}
                                                                      {misc.generic_type ===
                                                                      "per_month"
                                                                        ? "Per Month"
                                                                        : ""}
                                                                      {misc.generic_type ===
                                                                      "per_year"
                                                                        ? "Per Year"
                                                                        : ""}
                                                                    </>
                                                                  ) : null}
                                                                </td>
                                                                <td data-label="Rate">
                                                                  $
                                                                  {misc.generic_rate &&
                                                                  misc.generic_rate >
                                                                    0
                                                                    ? misc.generic_rate.toFixed(
                                                                        2
                                                                      )
                                                                    : "0.00"}
                                                                </td>
                                                              </tr>
                                                            )
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                            {!services.cancellation_fee ===
                                            0 ? (
                                              <div className="">
                                                <h5
                                                  className=""
                                                  id={`heading-${services.id}-details`}
                                                >
                                                  Cancellation Fee
                                                </h5>
                                                <div
                                                  id={`collapse-${services.id}-details`}
                                                  className=""
                                                  aria-labelledby={`heading-${services.id}-details`}
                                                >
                                                  <div className="">
                                                    <div className="table-responsive">
                                                      <table className="table">
                                                        <thead>
                                                          <tr>
                                                            <th>Type</th>
                                                            <th>Value</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <th>N/A</th>
                                                            <td>
                                                              $
                                                              {services.cancellation_fee &&
                                                              services.cancellation_fee >
                                                                0
                                                                ? services.cancellation_fee.toFixed(
                                                                    2
                                                                  )
                                                                : "0.00"}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*<h3>{services.service_title}</h3>
                          <p
                            className="text"
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {services.product_desc}
                          </p>
                          <a data-bs-toggle="modal" data-bs-target={`#serviceModal${services.id}`} href="#"><i className="fas fa-arrow-right"></i></a>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* Link Modal MODAL START */}
        <div
          className="modal fade zoom"
          tabIndex="-1"
          id="modalMarketingLink"
          style={{ overflow: "hidden" }}
        >
          <div
            className="modal-dialog modal-xl modal-dialog-top"
            role="document"
            style={{ maxWidth: "95%", height: "95vh" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <div
                  className="modal-title"
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                  <strong>
                    <em className="icon ni ni-list-round"></em> Marketing Link
                  </strong>
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.modalHideMarketingLink();
                  }}
                ></button>
              </div>

              <div className="modal-body">
                <br />
                <iframe
                  src={this.state.currentlink}
                  title="Marketing Link"
                  width="100%"
                  height={newHeight * 0.61}
                  style={{ border: "none" }}
                ></iframe>
                {console.log("my screen => ", window.screen.height)}
              </div>
            </div>
          </div>
        </div>
        {/* LINK MODAL End */}
        {/* Link Modal MODAL START */}
        <div
          className="modal fade zoom"
          tabIndex="-1"
          id="modalMarketingPDFLink"
          style={{ overflow: "hidden" }}
        >
          <div
            className="modal-dialog modal-xl modal-dialog-top"
            role="document"
            style={{ maxWidth: "95%", height: "95vh" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <div
                  className="modal-title"
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                  <strong>
                    <em className="icon ni ni-list-round"></em> File
                  </strong>
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.modalHideMarketingPDF();
                  }}
                ></button>
              </div>

              <div className="modal-body">
                <br />
                <embed
                  src={this.state.currentPdfLink}
                  type="application/pdf"
                  width="100%"
                  height={newHeight * 0.61}
                />
              </div>
            </div>
          </div>
        </div>
        {/* LINK MODAL End */}
        {/* === END MODALS === */}
        <HelmetProvider>
          <Helmet>
            <script src="../../assets/theme/js/bootstrap.bundle.min.js"></script>
            <script src="../../assets/theme/js/jquery.appear.js"></script>
            <script src="../../assets/theme/js/jquery.easing.min.js"></script>
            <script src="../../assets/theme/js/jquery.magnific-popup.min.js"></script>
            <script src="../../assets/theme/js/modernizr.custom.13711.js"></script>
            <script src="../../assets/theme/js/swiper-bundle.min.js"></script>
            <script src="../../assets/theme/js/wow.min.js"></script>
            <script src="../../assets/theme/js/progress-bar.min.js"></script>
            <script src="../../assets/theme/js/circle-progress.js"></script>
            <script src="../../assets/theme/js/isotope.pkgd.min.js"></script>
            <script src="../../assets/theme/js/imagesloaded.pkgd.min.js"></script>
            <script src="../../assets/theme/js/jquery.nice-select.min.js"></script>
            <script src="../../assets/theme/js/count-to.js"></script>
            <script src="../../assets/theme/js/jquery.scrolla.min.js"></script>
            <script src="../../assets/theme/js/YTPlayer.min.js"></script>
            <script src="../../assets/theme/js/TweenMax.min.js"></script>
            <script src="../../assets/theme/js/rangeSlider.min.js"></script>
            <script src="../../assets/theme/js/jquery-ui.min.js"></script>
            <script src="../../assets/theme/js/validnavs.js"></script>
            <script src="../../assets/theme/js/main.js"></script>
          </Helmet>
        </HelmetProvider>
      </>
    );
  }
}

export default Theme9;
